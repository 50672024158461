import { ReactElement } from 'react';

import Button from '../v2/Button/Button';
import { Modal } from '../v2/Modal/Modal';
import { Typography } from '../v2/Typography/Typography';

type DeactivateCeremonyModalProps = {
  closeModal: () => void;
  onAccept: () => void;
  title: string;
  message: string | ReactElement;
  agreeText: string;
  disagreeText: string;
};

const ConfirmationModal = (props: DeactivateCeremonyModalProps) => {
  const { closeModal, onAccept, title, message, agreeText, disagreeText } =
    props;

  return (
    <Modal isOpen={true} onClose={() => closeModal()} height='auto'>
      <>
        <Typography className='mb-2' type='display-600'>
          {title}
        </Typography>
        <Typography className='mb-8' type='body-400'>
          <>{message}</>
        </Typography>

        <div className='flex justify-end w-full'>
          <Button variant='secondary' onClick={closeModal} className=' mr-3'>
            {disagreeText}
          </Button>
          <Button onClick={() => void onAccept()}>{agreeText}</Button>
        </div>
      </>
    </Modal>
  );
};

export default ConfirmationModal;

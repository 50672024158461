import { Buffer } from 'buffer';

import { useEffect, useState } from 'react';
import ReactFbPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TiktokPixel from 'tiktok-pixel';

import {
  OrderResponse,
  confirmpaymentSession,
  NewPaymentBody,
  Order,
  PayOption,
  OrderSources,
  confirmMultipleOrders,
} from '../../api/paywall';
import Container from '../../components/Container';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import Button from '../../components/v2/Button/Button';
import HearAboutModal from '../../components/v2/HearAboutModal/HearAboutModalNew';
import { Typography } from '../../components/v2/Typography/Typography';
import { ChecklistItemIdentifier } from '../../helpers/checklistHelpers/identifiers';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../helpers/helper';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../provider/authProvider';
import { useChecklist } from '../../provider/checklistProvider';

import paymentFailedPng from './images/payment-failed.png';
import paymentSuccessPng from './images/payment-success.png';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    dataLayer: any[];
  }
}

type payeeDetailType = {
  name: string;
  email: string;
};

interface LocationState {
  plan: PayOption;
  payee: string;
  payeeDetail: payeeDetailType;
  success: boolean;
}

type ButtonDetails = {
  redirect: string;
  text: string;
};

type OrderDetails = {
  order_source: OrderSources;
  prodDataName: string;
};

const PayConfirmation = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { isShowing, hideLoader } = useLoader();
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();

  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>();
  const [hashedEmail, setHashedEmail] = useState<string>('');

  const status = searchParams.get('st') === 't';
  const ref = searchParams.get('rf');
  const refs = searchParams.get('rfs');
  const isMerchandising = searchParams.get('m') === 't';
  const isOrdination = searchParams.get('o') === 't';
  const isGiftcard = searchParams.get('g') === 't';
  const orderDetailsb64 = searchParams.get('od');
  const orderDetails: OrderDetails = orderDetailsb64
    ? JSON.parse(Buffer.from(orderDetailsb64, 'base64').toString('utf8'))
    : undefined;

  const [showHearAboutModal, setShowHearAboutModal] = useState(false);

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  useEffect(() => {
    if (ref) {
      void confirmPaymentRef(ref);
    }
  }, [ref]);

  useEffect(() => {
    if (refs) {
      void confirmPaymentRefs(refs);
    }
  }, [refs]);

  useEffect(() => {
    if (hashedEmail) {
      // window.gtag('set', 'user_data', {
      //   sha256_email_address: hashedEmail,
      // });
    }
  }, [hashedEmail]);

  useEffect(() => {
    if (order) {
      if (isGiftcard || isMerchandising) {
        setShowHearAboutModal(false);
      } else {
        setShowHearAboutModal(true);
      }
    }
  }, [isGiftcard, isMerchandising, order]);

  const hash = async (string: string) => {
    string = string.trim().toLowerCase();
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    setHashedEmail(hashHex);
  };

  useEffect(() => {
    if (status) {
      if (order) {
        order.user?.email && hash(order.user?.email);

        // ReactFbPixel.track('Purchase', {
        //   currency: 'USD',
        //   value: order.actual_amount,
        // });
        void ReactFbPixel.fbq(
          'track',
          'Purchase',
          {
            currency: 'USD',
            value: order.actual_amount,
          },
          {
            eventID: `prov_${order.id}`,
          }
        );

        void ReactGA.event({
          category: 'PayWall',
          action: 'OrderPaymentSuccessful',
          label: 'OrderPaymentSuccessful',
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'order_complete',
          order_id: order.id,
          order_value: order.actual_amount,
          order_currency: 'USD',
          enhanced_conversion_data: {
            email: order.user?.email,
          },
        });

        // window.gtag('event', 'conversion', {
        //   send_to: 'AW-11021677486/qoHXCMOh6JoYEK7nxYcp',
        //   value: order.actual_amount,
        //   currency: 'USD',
        //   transaction_id: order.id,
        //   user_data: {
        //     email: order.user?.email,
        //   },
        // });

        void ReactGA.event('purchase', {
          transaction_id: order.id,
          value: order.actual_amount,
          currency: 'USD',
        });

        void TiktokPixel.track('CompletePayment', {
          content_type: 'product',
          quantity: 1,
          content_id: order.product_id,
          currency: 'USD',
          value: order.actual_amount,
        });
      }
    }
  }, [order]);

  const confirmPaymentRef = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref || '',
      };
      const sessionResponse: OrderResponse = await confirmpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        setOrder(sessionResponse.order);
        status
          ? openSnackBar('Payment processed successfully', 5000, 'success')
          : openSnackBar('Payment could not be processed', 5000, 'error');
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const confirmPaymentRefs = async (refs: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: refs || '',
      };
      const sessionResponse: OrderResponse = await confirmMultipleOrders(
        payBody
      );
      if (sessionResponse.success && sessionResponse.order) {
        //TODO - we have multiple orders here - what should happen?
        setOrder(sessionResponse.order);
        status
          ? openSnackBar('Payment processed successfully', 5000, 'success')
          : openSnackBar('Payment could not be processed', 5000, 'error');
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const getMainText = () => {
    if (!status)
      return `We are unable to process your payment at this time. Please try again. If you have received this message in error, contact support@provenance.co.`;
    if (isGiftcard)
      return "Your payment has been successfully processed. Your recipients will now have access to Provenance's full suite of digital tools to write a personalized ceremony with heartfelt vows.";
    if (isMerchandising)
      return `Thank you for your purchase. Keep an eye on your inbox ~ we’ll send you the survey link shortly.`;
    if (isOrdination)
      return `Your credentials and resources can now be accessed immediately both within your dashboard and within the email you just received.`;

    return `Your payment has been successfully processed. You and your invitees now have access to everything listed in your plan. Enjoy the journey! `;
  };

  const getTitleText = () => {
    if (!status) return 'Payment Failed';
    if (isOrdination) return 'Access Granted';

    return 'Payment Successful';
  };

  const getButtonDetails: () => ButtonDetails = () => {
    let redirect = '/dashboard';
    let text = 'Go to dashboard';

    if (isOrdination) {
      return {
        text: 'Access Now',
        redirect: `/ceremony/${order?.ceremony_id || '0'}/ordained`,
      };
    }

    if (!auth.user)
      return {
        redirect: 'https://www.provenance.co/',
        text: 'Back to Homepage',
      };

    if (!order || !order.ceremony_id) {
      return {
        redirect,
        text,
      };
    }

    if (
      order &&
      order.payment_type &&
      (order.payment_type === 'wp_pay_per_ceremony' ||
        order.payment_type === 'subscription')
    ) {
      void sendCustomerIoEventHandler('WP Payment Confirmed');

      void saveMemberChecklistItemUsingIdentifier(
        {
          completed: true,
        },
        ChecklistItemIdentifier.ADD_REST_OF_CLIENTS,
        true
      );

      return {
        text: 'Go to dashboard',
        redirect: `/ceremony/planner/dashboard`,
      };
    }

    if (order && order.type === 'guest_toast_builder') {
      void sendCustomerIoEventHandler('Guest Purchase Completed');

      return {
        text: 'Generate your toast',
        redirect: `/ceremony/${order.ceremony_id}/toast?generate=true`,
      };
    }

    if (order && order.is_ordination_order) {
      void sendCustomerIoEventHandler('Ordination Package Purchase Completed');
    }

    const { order_source } = orderDetails ?? {};

    if (!order_source)
      return {
        redirect,
        text,
      };

    /* if merchandising was purchased */
    if (order_source == OrderSources.merch_wt_editor) {
      text = 'Back to Welcome Toast Editor';
      redirect = `/welcome-toast-builder-ai/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.merch_cb_editor) {
      text = 'Back to Ceremony Builder Editor';
      redirect = `/ceremony-designer/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.merch_vb_editor) {
      text = 'Back to Vows Builder Editor';
      redirect = `/vow-builder-ai/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.merch_tb_editor) {
      text = 'Back to Toast Builder Editor';
      redirect = `/ai-toast-builder/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.merch_vb_landing) {
      text = 'Back to your Vows';
      redirect = `/ceremony/${order.ceremony_id}/vowbuilder`;
    }
    if (order_source == OrderSources.merch_tb_landing) {
      text = 'Back to your Toasts';
      redirect = `/ceremony/${order.ceremony_id}/guest`;
    }
    if (order_source.includes('cb_landing')) {
      text = 'Back to the Ceremony Builder';
      redirect = `ceremony-designer/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.vb_landing) {
      text = 'Back to your Vows';
      redirect = `/ceremony/${order.ceremony_id}/vowbuilder`;
    }
    if (order_source == OrderSources.tb_landing) {
      text = 'Back to your Toasts';
      redirect = `/ceremony/${order.ceremony_id}/guest`;
    }
    if (order_source == OrderSources.cb_editor) {
      text = 'Back to the Ceremony Builder Editor';
      redirect = `/ceremony-designer/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.vb_editor) {
      text = 'Back to your Vows';
      redirect = `/vow-builder-ai/${order.ceremony_id}`;
    }
    if (order_source == OrderSources.tb_editor) {
      text = 'Back to your Toasts';
      redirect = `/ai-toast-builder/${order.ceremony_id}`;
    }

    return {
      redirect,
      text,
    };
  };

  const buttonDetails = getButtonDetails();

  return (
    <div className='bg-containerbg min-h-4/5 flex flex-col space-between items-center justify-center overflow-x-hidden pb-10'>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />

      <Typography className='mt-8' type='display-50'>
        Provenance
      </Typography>
      <img
        src={status ? paymentSuccessPng : paymentFailedPng}
        className='w-20 h-20 mt-8'
      />
      <Typography type='display-200' className='mt-8'>
        {getTitleText()}
      </Typography>

      <Container className='content-center place-content-center mt-4 auto-rows-fr'>
        <div className='hidden md:block md:col-span-1 lg:col-span-3'></div>
        <div className='col-span-4 md:col-span-4 lg:col-span-6'>
          <div className=' p-6 flex flex-col items-center text-center'>
            <div className='grid grid-cols-12 gap-x-10'>
              <div className='col-span-1'></div>
              <div className='col-span-10 flex flex-col font-inter text-forest-primary text-center py-2'>
                <span className=''>{getMainText()}</span>
              </div>
            </div>
            {!status && auth.user && (
              <Button
                onClick={() => {
                  navigate(`/pay-summary/${order?.ceremony_id ?? ''}`);
                }}
              >
                Pay now
              </Button>
            )}
          </div>
        </div>
      </Container>

      <Button
        className='mt-8'
        onClick={() => {
          window.open(buttonDetails.redirect, '_self');
        }}
      >
        {buttonDetails.text}
      </Button>

      {status &&
        order &&
        order.id &&
        auth.user &&
        process.env.REACT_APP_ENV === 'production' && (
          <img
            id='_SHRSL_img_1'
            src={`https://www.shareasale.com/sale.cfm?tracking=${order.id}&amount=${order.actual_amount}&merchantID=135962&v=dev1.5&transtype=sale&currency=${order.currency}`}
            width='1'
            height='1'
          ></img>
        )}

      {showHearAboutModal && (
        <HearAboutModal
          onCancelClick={() => setShowHearAboutModal(false)}
          modalOpen={showHearAboutModal}
        />
      )}
    </div>
  );
};

export default PayConfirmation;

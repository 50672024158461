/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import { Ceremony, Member } from '../api/ceremony';
import { getMerchandiseProducts } from '../api/merchandising';
import { appToolOptions, OrderSources } from '../api/paywall';
import checkMarkGreen from '../assets/images/icons/Check.svg';
import upRightArrow from '../assets/images/icons/arrow-up-right.svg';
import infoIcon from '../assets/images/svg/info.svg';
import Button from '../components/v2/Button/Button';
import ButtonPill from '../components/v2/ButtonPill';
import { Typography } from '../components/v2/Typography/Typography';

import { useDashboard } from './dashboardProvider';
import { useOutput } from './outputProvider';
import { usePaywall } from './paywallProvider';

export type MerchandiseProduct = {
  id: number;
  name: string;
  title: string;
  description: string;
  attributes: string[];
  paywall_attributes: string[];
  price: number;
  original_price?: number;
  days_prior_speech: number;
  status: 'available' | 'not-available' | 'purchased';
  paywall_title: string;
  paywall_description: string;
  isWeddingTooSoon: () => boolean;
};

type MerchandiseContextType = {
  products: MerchandiseProduct[];
  selectedMerch: null | MerchandiseProduct;
  selectMerch: (value: string) => void;
  showMerchandisingModal: boolean;
  setShowMerchandisingModal: (value: boolean) => void;
  appTool: appToolOptions;
  setAppTool: (value: appToolOptions) => void;
  source: OrderSources | undefined;
  setSource: (value: OrderSources) => void;
  getButtonStatus: (
    productName: string
  ) => 'available' | 'not-available' | 'purchased';
  getButton: (productName: string, source?: OrderSources) => JSX.Element;
  showTooltip: (productName: string) => boolean;
};

export const MerchandiseContext = React.createContext<MerchandiseContextType>({
  products: [],
  selectedMerch: null,
  selectMerch: () => {},
  showMerchandisingModal: false,
  setShowMerchandisingModal: () => {},
  appTool: appToolOptions.none,
  setAppTool: () => {},
  getButtonStatus: () => 'not-available',
  getButton: () => <></>,
  source: undefined,
  setSource: () => {},
  showTooltip: () => false,
});

export const MerchandiseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [products, setProducts] = useState<MerchandiseProduct[]>([]);
  const [selectedMerch, setSelectedMerch] = useState<MerchandiseProduct | null>(
    null
  );
  const [showMerchandisingModal, setShowMerchandisingModal] =
    useState<boolean>(false);

  const [ceremony, setCeremony] = useState<Ceremony | undefined>();
  const [currentUser, setCurrentUser] = useState<Member | undefined>();

  const outputData = useOutput();
  const dashboardData = useDashboard();

  useEffect(() => {
    if (dashboardData.ceremony && dashboardData.currentUser) {
      setCeremony(dashboardData.ceremony);
      setCurrentUser(dashboardData.currentUser);
    } else {
      if (outputData.ceremony && outputData.currentUser) {
        setCeremony(outputData.ceremony);
        setCurrentUser(outputData.currentUser);
      }
    }
  }, [dashboardData, outputData]);

  const [appTool, setAppTool] = useState<appToolOptions>(appToolOptions.none);
  const { source, setSource } = usePaywall();

  useEffect(() => {
    const f = async () => {
      if (ceremony && appTool && currentUser) {
        try {
          const response = await getMerchandiseProducts(
            ceremony.id.toString(),
            appTool
          );

          const products = response.data;

          if (products) {
            setProducts(products);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    void f();
  }, [currentUser, ceremony, appTool]);

  const selectMerch = (name: string, source?: OrderSources) => {
    const merch = products.find((m) => m.name === name);
    if (merch) {
      setSelectedMerch(merch);
      setShowMerchandisingModal(true);
    } else console.log('ERROR: there is no merch with name: ' + name, products);

    if (source) setSource(source);
  };

  const getButtonStatus = (productName: string) => {
    const product = products.find((product) => product.name === productName);
    if (!product) return 'not-available';
    return product.status;
  };

  const getButton = (
    productName: string,
    source?: OrderSources,
    availableText?: string
  ) => {
    if (productName == 'booklet') {
      return (
        <Button size='100' variant='text'>
          <a href='https://tidd.ly/40C0wZ4' target='_blank'>
            <>
              Learn More
              <img src={upRightArrow} alt='navigate' className='ml-1 inline' />
            </>
          </a>
        </Button>
      );
    }

    const status = getButtonStatus(productName);

    if (status == 'not-available') {
      return (
        <ButtonPill
          text={
            <Typography type='body-200' variant='functional-low'>
              <>
                <img
                  className='mr-2 inline-block'
                  alt='product not available'
                  src={infoIcon}
                />
                Not available
              </>
            </Typography>
          }
          className='w-full bg-white flex align-center'
          size={'sm'}
        />
      );
    } else if (status == 'purchased') {
      return (
        <ButtonPill
          text={
            <Typography type='body-200' variant='functional-low'>
              <>
                <img
                  className='mr-2 inline-block'
                  alt='product not available'
                  src={checkMarkGreen}
                />
                Purchased
              </>
            </Typography>
          }
          className='w-full bg-white flex align-center'
          size={'sm'}
        />
      );
    } else if (status == 'available') {
      return (
        <Button
          size='100'
          variant='text'
          onClick={() => {
            selectMerch(productName, source);
          }}
        >
          {availableText || 'Learn More'}
        </Button>
      );
    }

    return <></>;
  };

  const showTooltip = (productName: string) => {
    const product = products.find((product) => product.name === productName);
    if (!product) return false;
    return product.status == 'not-available';
  };

  products.forEach((product) => {
    product.isWeddingTooSoon = () => {
      if (!ceremony || !ceremony.date) return false;
      const weddingDate = new Date(ceremony.date);
      const today = new Date();
      const daysUntilWedding = Math.floor(
        (weddingDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
      );
      return daysUntilWedding < product.days_prior_speech;
    };
  });

  const value = {
    products,
    selectedMerch,
    selectMerch,
    showMerchandisingModal,
    setShowMerchandisingModal,
    appTool,
    setAppTool,
    getButtonStatus,
    getButton,
    source,
    setSource,
    showTooltip,
  };
  // @ts-ignore
  return (
    <MerchandiseContext.Provider value={value}>
      {children}
    </MerchandiseContext.Provider>
  );
};

export const useMerchandise = () => {
  return useContext(MerchandiseContext);
};

/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleQuestion, ModuleWrapper } from '../components';

import shapesIcon from '../../../assets/images/icons/shapes.svg';
import useQuestions from '../../../hooks/useQuestions';
import useCeremony from '../../../hooks/useCeremony';
import { ModuleStatus } from '../enum';

import { useEffect } from 'react';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import { generalLog } from '../../../api/ceremony';
import { CTAProvider, useCTA } from '../../../provider/ctaProvider';
import GenerateButton from '../components/ModuleWrapper/GenerateButton';

export const LogisticsModule = ({
  hideWrapper = false,
  onModuleStatusChange,
}: {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
}) => {
  const navigate = useNavigate();
  const ceremonyId = useParams()?.ceremonyId;

  const {
    setDraftingModalOpen,
    setSelectedModule,
    setGenerateDraftInitiated,
    setSelectedTab,
  } = useCeremonyBuilder();
  const { questions, fetchQuestions } = useQuestions();

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  const members = { couple1, couple2, officiant, currentUser };

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(Module.DirectionalQuestions, ceremony.id.toString());
    }
  }, [ceremony]);

  const handleGenerateClick = () => {
    if (ceremonyId) {
      setSelectedModule(Module.DirectionalQuestions);
      setSelectedTab('Ceremony');
      setDraftingModalOpen(true);
      setGenerateDraftInitiated(true);
      generalLog({
        ceremony_id: ceremonyId,
        activity: `Generate - Welcome & Procession Module`,
      });
      navigate(`/ceremony-designer/${ceremonyId}`);
      void fetchQuestions(Module.DirectionalQuestions, ceremonyId);
    } else {
      navigate(-1);
    }
  };

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={questions?.length || 0}
      onModuleStatusChange={onModuleStatusChange}
      module={Module.DirectionalQuestions}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          chapter: 'Chapter 1',
          renderIcon: () => <img src={shapesIcon} draggable={false} />,
        }}
        summaryProps={{
          moduleName: 'Welcome & Procession',
          moduleDescription:
            'A wedding ceremony isn’t one-size-fits-all. Every couple’s needs are unique – a combination of cultural tradition and personal nuance. Answering these initial questions below will help ensure that the all of these details come together into a cohesive script — down to the last detail.',
          numberOfQuestions: 7,
          estimatedTime: 5,
        }}
        questionsHeaderProps={{
          renderCenterButton: () => (
            <GenerateButton onClick={handleGenerateClick} size='100' />
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              size='100'
              variant='secondary'
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
      >
        {ceremony &&
          questions &&
          questions.map((questionData, questionIndex) => {
            return (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions?.length ?? 0}
                members={members}
              />
            );
          })}

        <div className='flex justify-center mb-16'>
          <GenerateButton
            fullWidth={true}
            onClick={handleGenerateClick}
            size='200'
          />
        </div>
      </ModuleWrapper>
    </CTAProvider>
  );
};

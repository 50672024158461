import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import './SelectField.scss';
import { Option, SelectFieldProps } from './typings.d';

export const SelectField = ({
  label,
  value,
  options,
  labelVariant,
  wrapperProps,
  fontVariant,
  onChange,
  placeholder,
  getOptionDisabled = (option: Option) => !!option?.disabled,
  ...props
}: SelectFieldProps) => {
  const muiLabel = !!label && labelVariant === 'standard' ? label : undefined;
  
  return (
    <div
      {...wrapperProps}
      className={classNames('SelectField', wrapperProps?.className, {
        'SelectField-grotesk': fontVariant === 'grotesk',
        'SelectField-inter': fontVariant === 'inter',
      })}
    >
      {labelVariant === 'outside' && !!label && (
        <React.Fragment>
          {typeof label === 'string' && (
            <label className='SelectField__label'>{label}</label>
          )}
          {typeof label !== 'string' && (
            <div className='SelectField__label'>{label}</div>
          )}
        </React.Fragment>
      )}
      <Select
        {...props}
        {...{ value, label: muiLabel }}
        // eslint-disable-next-line
        displayEmpty={!!placeholder}
        onChange={(evt) => void onChange?.(evt.target.value as any)}
        MenuProps={{
          classes: {
            paper: 'max-h-[300px] lg:max-h-[400px] custom-scrollbar',
          },
        }}
      >
        {!!placeholder && <MenuItem disabled value="">{placeholder}</MenuItem>}
        {options.map((option) => {
          const _value = props.multiple === true && Array.isArray(value)
            ? value || []
            : [value];
          const isChecked = _value.filter(Boolean).some(opt => opt === option.value);
          const disabled = getOptionDisabled?.(option) ?? option.disabled;
          return (
            <MenuItem
              key={`option-${option.value}`}
              value={option.value}
              disabled={disabled || option.disabled}
            >
              {props.multiple === true && <Checkbox checked={isChecked} />}
              <ListItemText primary={option.label} />
            </MenuItem>
          )
        })}
      </Select>
    </div>
  );
};

import { Speaker } from './friends';
import { OutputData } from './output';
import { Order } from './paywall';
import { _request } from './request';

export interface Member {
  id?: number;
  ceremony_id?: number;
  legal_name?: string;
  preferred_name: string;
  email?: string;
  phone?: string;
  prefered_pronouns?: string;
  relation_with_couple?: string;
  officiated_before?: boolean;
  officiated_times?: number;
  ordained?: boolean;
  member_type: MemberType;
  member_sub_type?: MemberSubType;
  subscribe_phone?: boolean;
  subscribe_email_officiant?: boolean;
  subscribe_email?: boolean;
  removed_from_ceremony?: boolean;
  contribute_to_wedding?: boolean;
  share_vows_time_with_officiant?: boolean;
  share_vows_content_with_officiant?: boolean;
  share_vows_time_with_partner?: boolean;
  share_vows_content_with_partner?: boolean;
  share_vows_time_with_planner?: boolean;
  share_vows_content_with_planner?: boolean;
  user_id?: number;
  created_by?: number;
  createdAt?: Date;
  updatedAt?: Date;
  mem_id?: number;
  output?: OutputData[];
}
export enum MemberType {
  officiant = 'officiant',
  bride = 'bride',
  groom = 'groom',
  other = 'other',
  weddingPlanner = 'wedding_planner',
  guests = 'guest',
  ritualMember = 'ritual-member',
  reader = 'reader',
}

export enum MemberSubType {
  parent = 'parent',
  sibling = 'sibling',
  bestMan = 'best_man',
  friend = 'friend',
  cousin = 'cousin',
  grandparent = 'grandparent',
  maidOfHonor = 'maid_of_honor',
  weddingParty = 'wedding_party',
  auntUncle = 'aunt_uncle',
  mentor = 'mentor',
  other = 'other',
}

export type GenericResponse = {
  success: boolean;
  message: string;
  errors?: unknown;
};

export type LandingLogResponse = {
  success: boolean;
  message: string;
  data: LandingLog;
};

export type LandingLog = {
  id: number;
  ceremony_id: number;
  landing_page_identifier: string;
  user_id: number;
  createdAt: Date;
  updatedAt: Date;
};

export type ActivityLogResponse = {
  success: boolean;
  message: string;
  data: ActivityLog;
};

export type ActivityLog = {
  id: number;
  ceremony_id: number;
  activity: string;
  user_id: number;
  date: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type PricingOption = {
  id: number;
  ceremony_id: number;
  name: string;
  price: number;
  description: string;
  category: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  modules: PricingOptionModules[];
};

export type PricingOptionModules = {
  id: number;
  pricing_option_id: number;
  module_identifier: string;
};

export type CeremonyPlannerStatus = {
  id: number;
  user_id: number;
  ceremony_id: number;
  status: 'active' | 'inactive';
  createdAt: Date;
  updatedAt: Date;
};

export type CeremonyBody = {
  date?: string;
  created_by?: number;
  hidden?: boolean;
  wedding_place?: string;
  state?: string;
  city?: string;
  venue?: string;
  is_legal_valid?: boolean;
  how_long_ceremony?: string;
  basic_info_completed?: boolean;
  hide_officiant_address?: boolean;
  hide_partner_responses?: boolean;
  members?: Member[];
  speakers?: Speaker[];
  officiants?: Member[];
  createdAt?: Date;
  updatedAt?: Date;
  member_type?: string;
  couple?: Array<Member>;
  coupleName?: string;
  ceremony_planner_status?: CeremonyPlannerStatus[];
  isPlannerActivated?: boolean;
  isPlannerFreeTrial?: boolean;
  wedding_planner_status?: 'completed' | 'in_progress' | 'not_started';
  shouldActivate?: boolean;
  weddingPlannerCreated?: boolean;
  hasAccessTo?: string[];
  isCeremonyDeactivatedByPlanner?: boolean;
  vowslanding_test_category?: string;
  referral_id?: string;
  ordainment_test_category?: string;
  ordainment_bundle_test_category?: string;
};

export type Ceremony = {
  id: number;
} & CeremonyBody;

export type CeremonyResponse = {
  success: boolean;
  message: string;
  cer_id?: string;
  activated?: boolean;
};

export type CeremonyByIdResponse = {
  success: boolean;
  message: string;
  data: Ceremony;
};

export type CeremonyAccessibleModulesResponse = {
  success: boolean;
  message: string;
  data: PricingOption;
};

export type DownloadLogsBody = {
  ceremony_id: number | string;
};

export type GeneralLogsBody = {
  ceremony_id: number | string;
  activity: string;
};

export type LogLandingBody = {
  ceremony_id: number | string;
  identifier: string;
};

export type CeremonyWeddingPlannerStatusBody = {
  ceremonyId: number | string;
  status: CeremonyWeddingPlannerStatus;
};

export enum CeremonyWeddingPlannerStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
}

export interface CeremonyPlanResponse {
  success: boolean;
  message: string;
  data: CeremonyPlanResponseData;
}

export interface CeremonyPlanResponseData {
  accessibleModules: PricingOption;
  latestSubscription: Order;
  isWeddingPlannerActivated: boolean;
}

export interface AddressResult {
  home: string;
  postal_code: string;
  street: string;
  region: string;
  neighborhood: string;
  city: string;
  country: string;
  formatted_address: string;
  ward: string;
}

export interface GooglePlaceDetailResponse {
  success: boolean;
  message: string;
  data: AddressResult;
}

export type UpdateCeremonyVenueAndPlace = {
  id: string | number;
  venue: string;
  city: string;
  state: string;
  wedding_place: string;
  weddingDate: string | Date;
  venueUnknown: boolean;
  weddingDateUnknown: boolean;
};

export type UpdateCeremonyPrivacySettings = {
  id: string | number;
  hide_officiant_address: boolean;
  hide_partner_responses: boolean;
};

export const newCeremony = async (body: CeremonyBody) => {
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/create',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const newCeremonies = async (body: CeremonyBody[]) => {
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/create',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const updateCeremony = async (body: CeremonyBody, id: string) => {
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/onboarding/' + id,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const updateCeremonyWeddingPlannerStatus = async (
  body: CeremonyWeddingPlannerStatusBody
) => {
  const responseBody = await _request<GenericResponse>({
    url: 'ceremony/wedding-planner-status',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const saveBasicInfo = async (ceremony: Ceremony, password?: string) => {
  const body = { ceremony, password };
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/basic-info/',
    method: 'POST',
    body,
  });
  return responseBody;
};

//for now only officiants and speakers
export const addCeremonyMembers = async (ceremony: Ceremony) => {
  const body = { ceremony };
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/add-members/',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const updateCeremonyVenueAndDate = async (
  ceremony: UpdateCeremonyVenueAndPlace
) => {
  const body = ceremony;
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/venue-and-date/',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const updateCeremonyPrivacySettings = async (
  ceremony: UpdateCeremonyPrivacySettings
) => {
  const body = ceremony;
  const responseBody = await _request<CeremonyResponse>({
    url: 'ceremony/privacy-settings/',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const getCeremonyById = async (id: string) => {
  const responseBody = await _request<CeremonyByIdResponse>({
    url: `ceremony/${id}`,
    method: 'GET',
  });
  return responseBody;
};

export const getCeremonyAccessibleModules = async (id: string) => {
  const responseBody = await _request<CeremonyPlanResponse>({
    url: `ceremony/get-accessible-modules/${id}`,
    method: 'GET',
  });
  return responseBody;
};

export const logDownload = async (body: DownloadLogsBody) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/add-download-log`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const generalLog = async (body: GeneralLogsBody) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/create-log`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const getLog = async (activity: string, ceremonyId: string) => {
  const responseBody = await _request<ActivityLogResponse>({
    url: `ceremony/get-log/${activity}/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const logLandingPage = async (body: LogLandingBody) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/create-landing-page-log`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const getLogLandingPage = async (
  identifier: string,
  ceremonyId: string
) => {
  const responseBody = await _request<LandingLogResponse>({
    url: `ceremony/landing-log/${identifier}/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const getGooglePlaceDetail = async (placeId: string) => {
  const responseBody = await _request<GooglePlaceDetailResponse>({
    url: `ceremony/get-place-detail/${placeId}`,
    method: 'GET',
  });
  return responseBody;
};

export const addUpdateCeremonyMember = async (
  ceremonyId: string,
  member: Member
) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremonymembers/add-update-member`,
    method: 'POST',
    body: { ceremonyId, member },
  });
  return responseBody;
};

export const updateCeremonyMember = async (member: Member) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremonymembers/update-member`,
    method: 'POST',
    body: member,
  });
  return responseBody;
};

export const deleteCeremonyMember = async (memberId: string | number) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremonymembers/delete-member`,
    method: 'POST',
    body: {
      memberId
    },
  });
  return responseBody;
};

export const deactivateCeremony = async (ceremony_id: string) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/deactivate`,
    method: 'POST',
    body: {
      ceremony_id,
    },
  });
  return responseBody;
};

export const sendCustomerIoEvent = async (
  eventName: string,
  eventData: any,
  customEmail?: string
) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/send-customer-io-event`,
    method: 'POST',
    body: {
      eventName,
      eventData,
      customEmail: customEmail || null,
    },
  });
  return responseBody;
};

export const sendMetaEvent = async (eventName: string, eventData: any) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/send-meta-event`,
    method: 'POST',
    body: {
      eventName,
      eventData,
    },
  });
  return responseBody;
};

export const syncCustomerIo = async ({
  ceremonyId,
}: {
  ceremonyId: string | number;
}) => {
  const responseBody = await _request<GenericResponse>({
    url: `ceremony/sync-customer-io`,
    method: 'POST',
    body: {
      ceremonyId,
    },
  });
  return responseBody;
};

export const toggleCeremonyVisibility = async (ceremonyId: string | number) => {
  try {
    const responseBody = await _request<GenericResponse>({
      url: `ceremony/toggle-visibility`,
      method: 'POST',
      body: {
        ceremonyId,
      },
    });
    return responseBody;
  } catch (e) {
    console.log(e);
    return false;
  }
};

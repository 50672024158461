import React from 'react';

import { MemberType } from '../../api/ceremony';
import elipsisSvg from '../../assets/images/icons/elipsis.svg';
import { getMemberTypeTitle } from '../../helpers/dropdownHelper';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Typography } from '../v2/Typography/Typography';

import './collaborator.scss';

import { Menu, MenuItem } from '@mui/material';

import { capitalizeFirstLetterOfEachWord } from '../../helpers/helper';

type CollaboratorProps = {
  name: string;
  role: MemberType;
  onClick: () => void;
  expanded: boolean;
  isPending?: boolean;
};

export const Collaborator = (props: CollaboratorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const open = Boolean(anchorEl);
  const size = useWindowSize();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { name, onClick, expanded, role, isPending } = props;
  return (
    <div className='Collaborator-root cursor-pointer'>
      <div className='Collaborator-avatar' onClick={onClick}>
        <Typography variant='functional-low' type='body-100'>
          {name[0]}
        </Typography>
      </div>
      {expanded && (
        <div className='ml-3' onClick={onClick}>
          <Typography variant='functional-low' type='body-400'>
            {capitalizeFirstLetterOfEachWord(name)}
          </Typography>
          <div className='flex'>
            <Typography
              variant='functional-low'
              type='body-100'
              className='text-forest-300'
            >
              {getMemberTypeTitle(role)}
            </Typography>
            {isPending && (
              <Typography
                variant='functional-low'
                type='body-100'
                className='ml-2 text-marygold-400'
              >
                Pending
              </Typography>
            )}
          </div>
        </div>
      )}
      {expanded && (
        <div className='ml-auto'>
          <img
            className='cursor-pointer'
            onClick={(ev) => {
              if (size.isMobile) {
                onClick();
              } else {
                handleClick(ev);
              }
            }}
            src={elipsisSvg}
          />
          <Menu
            PaperProps={{
              style: {
                backgroundColor: '#EFEDE7',
                boxShadow:
                  '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
                borderRadius: 0,
                borderWidth: '1px',
                borderColor: '#9AA3A0',
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              <Typography variant='functional-low' type='body-400'>
                Edit
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams } from 'react-router-dom';

import {
  GenericResponse,
  Member,
  MemberType,
  generalLog,
} from '../../../api/ceremony';
import { downloadOutput } from '../../../api/output';
import { OrderSources, appToolOptions } from '../../../api/paywall';
import { sendWeddingApproachingReminder } from '../../../api/planner';
import drawingBooklet from '../../../assets/images/image/drawing_booklet.png';
import drawingPen from '../../../assets/images/image/drawing_pen.png';
import drawingSpeaker from '../../../assets/images/image/drawing_speaker.png';
import { PaywallModal, UpsellGifTypes } from '../../../components/PaywallModal/PaywallModal';
import Button from '../../../components/v2/Button/Button';
import CampaignItem from '../../../components/v2/CampaignItem/CampaignItem';
import { MerchandisingCard } from '../../../components/v2/MerchandisingCards/MerchandisingCard';
import { MerchandisingModal } from '../../../components/v2/MerchandisingModal/MerchandisingModal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { VowsDashboardItem } from '../../../components/v2/VowsDashboardItem/VowsDashboardItem';
import {
  getCoupleName,
  getMemberName,
  getVowsTitle,
} from '../../../helpers/ceremonyHelper';
import renderText from '../../../helpers/renderText';
import useLoader from '../../../hooks/useLoader';
import useSpeechData from '../../../hooks/useSpeechData';
import useVows from '../../../hooks/useVows';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useMerchandise } from '../../../provider/merchandisingProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { usePaywall } from '../../../provider/paywallProvider';
import { useAllQuestions } from '../../../provider/questionsProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import { AccessControlModal } from '../../AiCeremonyDesigner/AccesControlModal';
import PlannerItem from '../PlannerDashboard/PlannerItem';

import VowBuilderLocked from './VowBuilderLocked';

export const VowBuilderDashboard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    ceremony,
    couple1,
    couple2,
    currentUser,
    currentRole,
    setAddModalOpen,
    setMemberForEditing,
    setMemberType,
  } = useDashboard();
  const { openSnackBar } = useSnackbar();
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { ritualMinutes, fetchAllQuestions } = useAllQuestions();
  const { setSource } = usePaywall();

  const {
    getVows,
    vowsCouple1,
    vowsCouple2,
    vowsCouple1Duration,
    vowsCouple2Duration,
    vowsCouple1Tone,
    vowsCouple2Tone,
    vowsCouple1TargetLength,
    vowsCouple2TargetLength,
  } = useVows();
  const {
    hasAccessToVowsAi,
    fetchAccessibleModulesIdentifiers,
    fetchingAccessibleModulesInProgress,
  } = useAccessibleModule();

  const size = useWindowSize();
  const { setAppTool } = useMerchandise();

  const { speechData, getCeremonySpeeches } = useSpeechData();

  useEffect(() => {
    if (
      currentRole &&
      params.ceremonyId &&
      !isNaN(parseInt(params.ceremonyId ?? ''))
    ) {
      void fetchAllQuestions(params.ceremonyId);
      if (currentRole === MemberType.officiant) {
        void getCeremonySpeeches(parseInt(params.ceremonyId));
      }
      void fetchAccessibleModulesIdentifiers(params.ceremonyId);
    }
  }, [params.ceremonyId, currentRole]);

  useEffect(() => {
    if (ceremony && couple1 && couple2)
      void getVows(ceremony.id.toString(), couple1, couple2);

    setAppTool(appToolOptions.vows_builder);
  }, [ceremony]);

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);

  const onSendWeddingApproachingReminder = async (memberId: number) => {
    if (ceremony) {
      try {
        const reminderResponse: GenericResponse =
          await sendWeddingApproachingReminder(memberId, ceremony.id);
        if (reminderResponse.success) {
          openSnackBar('Reminder email sent successfully');
        }
      } catch (err) {
        openSnackBar('Something went wrong in sending reminder', 'error');
      }
    }
  };

  if (!ceremony || !couple1 || !couple2 || !currentUser) {
    return null;
  }

  const merchCardClassNames = 'lg:w-4/12 lg:block md:flex md:flex-row sm:block';

  const onExportVowsOutputPDF = async (
    member?: Member,
    currentUser?: Member
  ) => {
    if (ceremony && member && currentUser) {
      showLoader();
      const blob = await downloadOutput(
        ceremony.id,
        'vows',
        true,
        true,
        ritualMinutes,
        member?.id
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = getVowsTitle(member, currentUser);
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      hideLoader();
    }
  };

  const onUpgradeButtonClick = () => {
    if (ceremony) {
      ReactGA.event({
        category: 'PayWall',
        action: 'Unlock',
        label: 'Vows',
      });
      setSource(OrderSources.vb_landing);
      generalLog({
        ceremony_id: ceremony.id,
        activity: `Unlock - Vows`,
      })
        .then(() => {
          setIsPaywallModalOpen(true);
        })
        .catch((err) => console.error(err));
    }
  };

  if (fetchingAccessibleModulesInProgress) {
    return <></>;
  }

  return (
    <>
      {!hasAccessToVowsAi &&
      (currentUser?.member_type === MemberType.bride ||
        currentUser?.member_type === MemberType.groom ||
        currentUser?.member_type === MemberType.other) ? (
        <VowBuilderLocked onUpgradeButtonClick={onUpgradeButtonClick} />
      ) : (
        <div className='VowBuilderDashboard VowBuilderDashboard-root flex flex-col gap-8 mt-10 lg:mt-0'>
          <div className='PlannerDashboard-section__header flex flex-col lg:flex-row lg:items-center'>
            <AccessControlModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
            />
            <div className='flex flex-col flex-1'>
              <Typography type='display-400'>Vow Builder</Typography>
              {!size.isMobile && (
                <Typography className='max-w-[550px] mt-2' type='body-400'>
                  {renderText('vow_builder_description')}
                </Typography>
              )}
            </div>
            <div>
              {(currentUser?.member_type === MemberType.bride ||
                currentUser?.member_type === MemberType.groom ||
                currentUser?.member_type === MemberType.other) && (
                <Button
                  variant='secondary'
                  size='100'
                  onClick={() => {
                    if (hasAccessToVowsAi) {
                      setModalOpen(true);
                    } else {
                      setIsPaywallModalOpen(true);
                    }
                  }}
                >
                  Vow Privacy
                </Button>
              )}
            </div>
          </div>
          <div className='VowBuilderDashboard-vows flex flex-col gap-4'>
            {currentUser?.member_type === MemberType.officiant ? (
              <>
                <div className='flex flex-col md:flex-row gap-4 w-full'>
                  {couple1 && currentUser && (
                    <PlannerItem
                      title={getVowsTitle(couple1, currentUser)}
                      time={vowsCouple1Duration}
                      targetTime={vowsCouple1TargetLength}
                      showTime={couple1.share_vows_time_with_officiant || false}
                      progress={speechData?.vows_status_couple1 || 0}
                      allowExport={
                        couple1.share_vows_content_with_officiant || false
                      }
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onExportPDF={
                        couple1.share_vows_content_with_officiant
                          ? () => onExportVowsOutputPDF(couple1)
                          : undefined
                      }
                      url={`/preview-vows/${ceremony.id}/${couple1.id || ''}`}
                      //intendedTone={vowsCouple1Tone}
                      name={getMemberName(couple1)}
                      coupleName={
                        couple2
                          ? getCoupleName(couple1, couple2)
                          : getMemberName(couple1)
                      }
                      notInvited={!couple1.email}
                      pendingInvitation={!couple1.user_id}
                      isPrivate={
                        !couple1.share_vows_content_with_officiant || false
                      }
                      isDeactivated={ceremony.isCeremonyDeactivatedByPlanner}
                      onInvite={() => {
                        setMemberType(couple1.member_type);
                        setMemberForEditing(couple1);
                        setAddModalOpen(true);
                      }}
                      onSendWeddingReminder={() => {
                        couple1?.id &&
                          void onSendWeddingApproachingReminder(couple1.id);
                      }}
                    />
                  )}
                  {couple2 && currentUser && (
                    <PlannerItem
                      title={getVowsTitle(couple2, currentUser)}
                      time={vowsCouple2Duration}
                      targetTime={vowsCouple2TargetLength}
                      showTime={couple2.share_vows_time_with_officiant || false}
                      progress={speechData?.vows_status_couple2 || 0}
                      allowExport={
                        couple2.share_vows_content_with_officiant || false
                      }
                      onExportPDF={
                        couple2.share_vows_content_with_officiant
                          ? () => onExportVowsOutputPDF(couple2)
                          : undefined
                      } //onExportVowsOutputPDF
                      url={`/preview-vows/${ceremony.id}/${couple2.id || ''}`}
                      //intendedTone={vowsCouple2Tone}
                      name={getMemberName(couple2)}
                      coupleName={
                        couple1
                          ? getCoupleName(couple1, couple2)
                          : getMemberName(couple2)
                      }
                      notInvited={!couple2.email}
                      pendingInvitation={!couple2.user_id}
                      isPrivate={
                        !couple2.share_vows_content_with_officiant || false
                      }
                      isDeactivated={ceremony.isCeremonyDeactivatedByPlanner}
                      onInvite={() => {
                        setMemberType(couple2.member_type);
                        setMemberForEditing(couple2);
                        setAddModalOpen(true);
                      }}
                      onSendWeddingReminder={() => {
                        couple2?.id &&
                          void onSendWeddingApproachingReminder(couple2.id);
                      }}
                    />
                  )}
                </div>
                <div className='flex flex-row justify-center items-center'>
                  <Typography>
                    <>
                      If you want see how the vows fit into the ceremony you can
                      do so in the{' '}
                      <span
                        onClick={() =>
                          navigate(`/ceremony-designer/${ceremony.id}`)
                        }
                        className='underline cursor-pointer'
                      >
                        Script editor
                      </span>
                      .
                    </>
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <VowsDashboardItem
                  variant='vows'
                  member={currentUser}
                  showVows={true}
                  showTime={true}
                  vowsTone={
                    currentUser.id === couple1.id
                      ? vowsCouple1Tone
                      : vowsCouple2Tone
                  }
                  setIsPaywallModalOpen={(isPaywallModalOpen) =>
                    setIsPaywallModalOpen(isPaywallModalOpen)
                  }
                  vows={
                    currentUser.id === couple1.id ? vowsCouple1 : vowsCouple2
                  }
                  vowsTargetLength={
                    currentUser.id === couple1.id
                      ? vowsCouple1TargetLength
                      : vowsCouple2TargetLength
                  }
                  vowsTime={
                    couple1?.id === currentUser?.id
                      ? vowsCouple1Duration
                      : vowsCouple2Duration
                  }
                  canEditVows={true}
                  hasAccess={hasAccessToVowsAi}
                  title={'Your Vows'}
                  ceremonyId={ceremony?.id.toString()}
                  text={
                    'Write meaningful vows without the stress. Answer a few curated questions about your favorite stories, memories, and moments of love, and we’ll help generate a first draft.'
                  }
                  onUpgradeButtonClick={onUpgradeButtonClick}
                />
                <VowsDashboardItem
                  variant='vows'
                  vows={
                    currentUser.id === couple1.id ? vowsCouple2 : vowsCouple1
                  }
                  member={couple1?.id === currentUser?.id ? couple2 : couple1}
                  showTime={
                    couple1?.id === currentUser?.id
                      ? couple2?.share_vows_time_with_partner
                      : couple1?.share_vows_time_with_partner
                  }
                  showVows={
                    couple1?.id === currentUser?.id
                      ? couple2?.share_vows_content_with_partner
                      : couple1?.share_vows_content_with_partner
                  }
                  vowsTime={
                    couple1?.id === currentUser?.id
                      ? vowsCouple2Duration
                      : vowsCouple1Duration
                  }
                  vowsTone={
                    couple1?.id === currentUser?.id
                      ? vowsCouple2Tone
                      : vowsCouple1Tone
                  }
                  vowsTargetLength={
                    couple1?.id === currentUser?.id
                      ? vowsCouple2TargetLength
                      : vowsCouple1TargetLength
                  }
                  canEditVows={false}
                  title={getVowsTitle(
                    couple1?.id === currentUser?.id ? couple2 : couple1,
                    currentUser
                  )}
                  text={
                    (
                      couple1?.id === currentUser?.id
                        ? couple2?.share_vows_content_with_partner
                        : couple1?.share_vows_content_with_partner
                    )
                      ? 'View your partners’ work-in-progress vows here to collaborate on your mutual speeches ahead of the big day.'
                      : 'Your partner wants to keep their vows a surprise for the big day. No peeking allowed :), but you can see their relative length and tone here to ensure your vows are balanced.'
                  }
                  ceremonyId={ceremony.id.toString()}
                  onUpgradeButtonClick={onUpgradeButtonClick}
                />
              </>
            )}
          </div>

          <div>
            <CampaignItem
              regularComponent={
                <Typography type='display-100'>
                  Need some help with your vows?
                </Typography>
              }
              renderCampaignComponent={(
                discountPercentage,
                couponCode,
                campaignText
              ) => (
                <>
                  <Typography type='display-100'>
                    Need some help with your vows?
                  </Typography>
                  <Typography type='display-100'>
                    {`Get ${discountPercentage}% OFF Public Speaking & Professional Speechwriting Support!`}
                  </Typography>
                </>
              )}
            />

            <div className='lg:flex mt-3'>
              <MerchandisingCard
                productName={'speechwriting'}
                className={merchCardClassNames}
                title={'Want some honest feedback on your writing?'}
                description={
                  'Our professional speechwriters will review your draft and provide thoughtful suggestions and edits. '
                }
                image={
                  <img
                    src={drawingPen}
                    alt='speechwriting'
                    className='inline w-24 max-w-max m-2'
                  />
                }
                source={OrderSources.merch_vb_landing}
                tooltip='The professionals are on it! You purchased the speech editing and coaching bundle, so our experts are here to help you with both.'
              />
              <MerchandisingCard
                productName={'speechwriting+public_speaking'}
                className={merchCardClassNames}
                title={'Feeling anxious about public speaking?'}
                description={
                  'Work with a professional coach who will elevate your public speaking skills and share feedback on your draft, so you are ready to speak confidently on the big day.'
                }
                image={
                  <img
                    src={drawingSpeaker}
                    alt='speechwriting'
                    className='inline w-24 max-w-max m-2'
                  />
                }
                source={OrderSources.merch_vb_landing}
                tooltip='You purchased speech editing only. Public Speaking services are not available with this package. If you’ve made an error and meant to purchase our speech editing and coaching bundle, please email speechwriting@provenance.co '
              />
              <MerchandisingCard
                productName={'booklet'}
                className={merchCardClassNames}
                title='Looking for vow books?'
                description='Check out our favorites that will look great in your photos and become cherished keepsakes after the big day.'
                image={
                  <img
                    src={drawingBooklet}
                    alt='booklet'
                    className='inline w-24 max-w-max m-2'
                  />
                }
              />
            </div>
          </div>

          {currentUser?.id ? (
            <MerchandisingModal
              ceremonyId={ceremony.id.toString()}
              ceremonyDate={ceremony.date}
              userId={currentUser.id?.toString()}
            />
          ) : null}
        </div>
      )}
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
        type={'vows'}
        referrer={'vows'}
        ceremonyId={ceremony.id}
        displayGif={UpsellGifTypes.vowsBuilder}
      />
    </>
  );
};

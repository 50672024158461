import classNames from 'classnames';
import { useState } from 'react';

import { Member, MemberType } from '../../api/ceremony';
import { useDashboard } from '../../provider/dashboardProvider';
import { useAccessibleModule } from '../../provider/moduleAccessProvider';
import { AddMemberModal } from '../NewPeopleModal/components/AddMemberModal';
import { PaywallModal, PaywallModalTypes } from '../PaywallModal/PaywallModal';
import { Typography } from '../v2/Typography/Typography';

import { Collaborator } from './Collaborator';
import { InviteCollaborator } from './InviteCollaborator';

type CollaboratorsProps = {
  couple1?: Member;
  couple2?: Member;
  weddingPlanner?: Member;
  officiants?: Member[];
  currentUser?: Member;
  expanded: boolean;
};

export const Collaborators = ({
  couple1,
  couple2,
  currentUser,
  weddingPlanner,
  officiants,
  expanded,
}: CollaboratorsProps) => {
  const {
    setAddModalOpen,
    setMemberType,
    setMemberForEditing,
    handleAddMemberClick,
    ceremony,
    currentRole,
  } = useDashboard();

  const { hasAccessToInviteOfficiant, hasAccessToInviteWeddingPlanner } =
    useAccessibleModule();

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const [paywallModalType, setPaywallModalType] = useState<PaywallModalTypes>();

  return (
    <>
      <div
        className={classNames('mt-6 Collaborators-root', {
          'min-w-[224px]': expanded,
        })}
      >
        {expanded && (
          <Typography
            variant='functional-high'
            type='body-100'
            className='px-[19px] mb-2'
          >
            COLLABORATORS
          </Typography>
        )}
        <div className='px-[19px] py-[9px]'>
          {currentUser && currentUser.user_id === ceremony?.created_by && (
            <Collaborator
              name={currentUser.legal_name || ''}
              role={currentUser.member_type}
              onClick={() => {
                setMemberType(currentUser.member_type);
                setMemberForEditing(currentUser);
                setAddModalOpen(true);
              }}
              expanded={expanded}
            />
          )}

          {couple1 && currentUser && currentUser.id !== couple1?.id && (
            <>
              {couple1.legal_name !== 'TBD TBD' ? (
                <Collaborator
                  name={couple1.legal_name || ''}
                  role={couple1.member_type}
                  onClick={() => {
                    setMemberType(couple1.member_type);
                    setMemberForEditing(couple1);
                    setAddModalOpen(true);
                  }}
                  expanded={expanded}
                  isPending={!couple1.user_id && couple1.email ? true : false}
                />
              ) : (
                <InviteCollaborator
                  text='Invite Partner'
                  onClick={() => {
                    setMemberType(couple1.member_type);
                    setMemberForEditing(couple1);
                    setAddModalOpen(true);
                  }}
                  expanded={expanded}
                />
              )}
            </>
          )}

          {!couple1 && currentUser && (
            <InviteCollaborator
              text='Invite Partner'
              onClick={() => {
                setMemberType(MemberType.other);
                setMemberForEditing(undefined);
                setAddModalOpen(true);
              }}
              expanded={expanded}
            />
          )}

          {!couple2 && currentUser && (
            <InviteCollaborator
              text='Invite Partner'
              onClick={() => {
                setMemberType(MemberType.other);
                setMemberForEditing(undefined);
                setAddModalOpen(true);
              }}
              expanded={expanded}
            />
          )}

          {couple2 && currentUser && currentUser.id !== couple2?.id && (
            <>
              {couple2.legal_name !== 'TBD TBD' ? (
                <Collaborator
                  name={couple2.legal_name || ''}
                  role={couple2.member_type}
                  onClick={() => {
                    setMemberType(couple2.member_type);
                    setMemberForEditing(couple2);
                    setAddModalOpen(true);
                  }}
                  expanded={expanded}
                  isPending={!couple2.user_id && couple2.email ? true : false}
                />
              ) : (
                <InviteCollaborator
                  text='Invite Partner'
                  onClick={() => {
                    setMemberType(couple2.member_type);
                    setMemberForEditing(couple2);
                    setAddModalOpen(true);
                  }}
                  expanded={expanded}
                />
              )}
            </>
          )}

          {officiants &&
            officiants.length > 0 &&
            officiants.map((o, i) => (
              <Collaborator
                key={i}
                name={o.legal_name || ''}
                role={o.member_type}
                onClick={() => {
                  setMemberType(o.member_type);
                  setMemberForEditing(o);
                  setAddModalOpen(true);
                }}
                expanded={expanded}
                isPending={!o.user_id}
              />
            ))}
          <InviteCollaborator
            text='Invite Officiant'
            onClick={() => {
              (hasAccessToInviteOfficiant ||
                currentRole === 'wedding_planner') &&
                (setMemberForEditing(undefined),
                handleAddMemberClick(MemberType.officiant));

              if (
                !hasAccessToInviteOfficiant &&
                currentRole !== 'wedding_planner'
              ) {
                setPaywallModalType('officiant');
                setIsPaywallModalOpen(true);
              }
            }}
            expanded={expanded}
          />
          {!weddingPlanner || !weddingPlanner.email ? (
            <InviteCollaborator
              text='Invite Wedding Planner'
              onClick={() => {
                if (currentUser && hasAccessToInviteWeddingPlanner) {
                  handleAddMemberClick(
                    MemberType.weddingPlanner,
                    weddingPlanner,
                    currentUser.id === couple1?.id ? couple2 : couple1
                  );
                }

                if (!hasAccessToInviteWeddingPlanner) {
                  setPaywallModalType('weddingPlanner'),
                    setIsPaywallModalOpen(true);
                }
              }}
              expanded={expanded}
            />
          ) : currentUser?.id !== weddingPlanner.id ? (
            <Collaborator
              name={weddingPlanner?.legal_name || ''}
              role={weddingPlanner.member_type}
              onClick={() => {
                setMemberType(weddingPlanner.member_type);
                setMemberForEditing(weddingPlanner);
                setAddModalOpen(true);
              }}
              expanded={expanded}
              isPending={!weddingPlanner.user_id}
            />
          ) : null}
        </div>
      </div>
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
        type={paywallModalType as PaywallModalTypes}
        referrer={paywallModalType || ''}
        ceremonyId={ceremony?.id || 0}
        isCeremonyBuilder={true}
      />
    </>
  );
};

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import { sendNPStoAPI, getSubmittedNPS } from '../api/nps';

import { useCeremonyBuilder } from './ceremonyBuilderProvider';
import { useChecklist } from './checklistProvider';
import { useToastBuilder } from './toastBuilderProvider';
import { useVowBuilder } from './vowBuilderProvider';
import { useWelcomeToastBuilder } from './welcomeToastBuilderProvider';

type NPSContextType = {
  ceremonyCompleteModalOpen: boolean;
  setCeremonyCompleteModalOpen: (value: boolean) => void;
  loadNPS: boolean;
  setLoadNPS: (value: boolean) => void;
  loadInvites: boolean;
  setLoadInvites: (value: boolean) => void;
  loadThanks: boolean;
  setLoadThanks: (value: boolean) => void;
  loadFeedback: boolean;
  setLoadFeedback: (value: boolean) => void;
  npsValue: number | undefined;
  setNpsValue: (value: number) => void;
  feedback: string | undefined;
  setFeedback: (value: string) => void;
  sendFeedback: (
    ceremony_id: number,
    user_id: number,
    type_of_feedback: string
  ) => void;
  showCompletionModal: () => void;
  exitCompletionModal: () => void;
  showNPSModal: () => void;
  showInvites: () => void;
  showFeedback: () => void;
  finishFeedback: () => void;
  setNPSUserId: (value: number) => void;
  setNPSCeremonyId: (value: number) => void;
  userSubmittedNPS: boolean;
  setUserSubmittedNPS: (value: boolean) => void;
  setNPSSection: (value: string) => void;
  fetchSubmittedNPS: () => void;
};

type bodyType = {
  ceremony_id: number;
  user_id: number;
  type_of_feedback: string;
  npsValue: number;
  feedback?: string;
};

export const NPSContext = React.createContext<NPSContextType>({
  ceremonyCompleteModalOpen: false,
  setCeremonyCompleteModalOpen: () => {},
  loadNPS: false,
  setLoadNPS: () => {},
  loadInvites: false,
  setLoadInvites: () => {},
  loadThanks: false,
  setLoadThanks: () => {},
  loadFeedback: false,
  setLoadFeedback: () => {},
  npsValue: undefined,
  setNpsValue: () => {},
  feedback: undefined,
  setFeedback: () => {},
  sendFeedback: () => {},
  showCompletionModal: () => {},
  exitCompletionModal: () => {},
  showNPSModal: () => {},
  showInvites: () => {},
  showFeedback: () => {},
  finishFeedback: () => {},
  setNPSUserId: () => {},
  setNPSCeremonyId: () => {},
  userSubmittedNPS: false,
  setUserSubmittedNPS: () => {},
  setNPSSection: () => {},
  fetchSubmittedNPS: () => {},
});

export const NPSProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadNPS, setLoadNPS] = useState(false);
  const [loadInvites, setLoadInvites] = useState(false);
  const [loadThanks, setLoadThanks] = useState(false);
  const [loadFeedback, setLoadFeedback] = useState(false);
  const [npsValue, setNpsValue] = useState<number | undefined>(undefined);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [userSubmittedNPS, setUserSubmittedNPS] = useState(false);
  const [NPSUserId, setNPSUserId] = useState<number | undefined>(undefined);
  const [NPSCeremonyId, setNPSCeremonyId] = useState<number | undefined>(
    undefined
  );
  const [NPSSection, setNPSSection] = useState<string>('');
  const { setCompletedModalOpen } = useVowBuilder();
  const { setCompletedModalOpen: setToastCompletedModalOpen } =
    useToastBuilder();
  const { setCompletedModalOpen: setCeremonyCompleteModalOpen } =
    useCeremonyBuilder();
  const { setCompletedModalOpen: setWelcomeToastCompleteModalOpen } =
    useWelcomeToastBuilder();

  const { fetchChecklistItems } = useChecklist();

  const sendFeedback = async (
    ceremony_id: number,
    user_id: number,
    type_of_feedback: string
  ) => {
    try {
      if (npsValue === undefined) return;

      const body: bodyType = {
        ceremony_id: ceremony_id,
        user_id: user_id,
        type_of_feedback: type_of_feedback,
        npsValue: npsValue ? npsValue : 0,
      };

      if (feedback) body['feedback'] = feedback;

      await sendNPStoAPI(body);

      void fetchChecklistItems(ceremony_id, false);
      setNpsValue(undefined);
      setFeedback(undefined);
      setUserSubmittedNPS(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubmittedNPS = async () => {
    try {
      if (NPSCeremonyId && NPSUserId) {
        const response = await getSubmittedNPS({
          ceremony_id: NPSCeremonyId,
          user_id: NPSUserId,
        });
        if (response) setUserSubmittedNPS(true);
        else setUserSubmittedNPS(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void fetchSubmittedNPS();
  }, [NPSCeremonyId, NPSUserId]);

  const showCompletionModal = () => {
    if (NPSSection === 'vows') {
      setCompletedModalOpen(true);
    } else if (NPSSection === 'toast') {
      setToastCompletedModalOpen(true);
    } else if (NPSSection === 'ceremony_builder') {
      setCeremonyCompleteModalOpen(true);
    } else if (NPSSection === 'welcome_toast') {
      setWelcomeToastCompleteModalOpen(true);
    }
  };

  const exitCompletionModal = () => {
    if (NPSSection === 'vows') {
      setCompletedModalOpen(false);
    } else if (NPSSection === 'toast') {
      setToastCompletedModalOpen(false);
    } else if (NPSSection === 'ceremony_builder') {
      setCeremonyCompleteModalOpen(false);
    } else if (NPSSection === 'welcome_toast') {
      setWelcomeToastCompleteModalOpen(false);
    }
  };

  const showNPSModal = () => {
    exitCompletionModal();
    if (!userSubmittedNPS) setLoadNPS(true);
    else showInvites();
  };

  const showInvites = () => {
    setLoadNPS(false);
    setLoadInvites(true);
    if (!userSubmittedNPS) {
      if (NPSUserId && NPSCeremonyId)
        void sendFeedback(NPSCeremonyId, NPSUserId, NPSSection);
    }
  };

  const showFeedback = () => {
    setLoadNPS(false);
    setLoadFeedback(true);
  };

  const finishFeedback = () => {
    console.log(NPSCeremonyId, NPSUserId);
    if (NPSUserId && NPSCeremonyId)
      void sendFeedback(NPSCeremonyId, NPSUserId, NPSSection);
    setLoadFeedback(false);
  };

  const value = {
    loadNPS,
    setLoadNPS,
    loadInvites,
    setLoadInvites,
    loadThanks,
    setLoadThanks,
    loadFeedback,
    setLoadFeedback,
    npsValue,
    setNpsValue,
    feedback,
    setFeedback,
    sendFeedback,
    showCompletionModal,
    exitCompletionModal,
    showNPSModal,
    showInvites,
    showFeedback,
    finishFeedback,
    setNPSUserId,
    setNPSCeremonyId,
    userSubmittedNPS,
    setUserSubmittedNPS,
    setNPSSection,
    fetchSubmittedNPS,
  };
  // @ts-ignore
  return <NPSContext.Provider value={value}>{children}</NPSContext.Provider>;
};

export const useNPS = () => {
  return useContext(NPSContext);
};

import classNames from 'classnames';

// import { getOrdained, OrdainedResponseModel } from '../../../../api/ordained';
import Button from '../../../../components/v2/Button/Button';
import { Typography } from '../../../../components/v2/Typography/Typography';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import ChecklistItemIcon from './ChecklistItemIcon';
import GemButton from './GemButton';
import MarkCompleteButton from './MarkCompleteButton';
import SkipTaskButton from './SkipTaskButton';
import WatchDemoButton from './WatchDemoButton';
import checkSvg from './images/check.svg';

export enum ChecklistItemStatus {
  notStarted = 'not-started',
  skipped = 'skipped',
  completed = 'completed',
}

type ChecklistItemProps = {
  title: string;
  iconBackground: string;
  iconSrc: string;
  status: ChecklistItemStatus;
  onClick: () => Promise<void> | void;
  onSkipTaskClick?: () => Promise<void> | void;
  onMarkCompleteClick?: () => Promise<void> | void;
  onWatchDemoClick?: () => void;
  onAfterCompletedClick?: () => void;
  showGem?: boolean;
  ordainedButtonText?: string;
  paidForOrdination?: boolean;
};
const ChecklistItem = (props: ChecklistItemProps) => {
  const {
    title,
    iconBackground,
    iconSrc,
    status,
    onClick,
    onSkipTaskClick,
    onMarkCompleteClick,
    onWatchDemoClick,
    onAfterCompletedClick,
    showGem,
    ordainedButtonText,
    paidForOrdination,
  } = props;

  const { isMobile } = useWindowSize();

  // const downloadCertificate = async () => {
  //   try {
  //     const response = await getOrdained();
  //     if (response.success) {
  //       window.open(
  //         `${
  //           process.env.REACT_APP_BASE_URL || ''
  //         }api/downloads/credentials-of-ministry/${response.data.hash}`,
  //         '_blank'
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div
      onClick={() => void onClick()}
      className={classNames('mt-4 flex flex-row cursor-pointer', {
        'checklist-item-completed':
          status === ChecklistItemStatus.completed ||
          status === ChecklistItemStatus.skipped,
        'border border-forest-primary hover:outline hover:outline-2 hover:outline-forest-primary rounded-3xl p-4 px-6 transition-[outline] duration-100':
          !isMobile,
        'mx-2 pb-4 border-b border-forest-100': isMobile,
      })}
    >
      <div className='flex flex-row items-start'>
        {status === ChecklistItemStatus.completed ||
        status === ChecklistItemStatus.skipped ? (
          <ChecklistItemIcon
            iconBackground={'bg-system-green'}
            iconSrc={checkSvg}
          />
        ) : (
          <ChecklistItemIcon
            iconBackground={iconBackground}
            iconSrc={iconSrc}
          />
        )}
      </div>
      <div
        className={classNames('flex ml-4 ', {
          'flex-col gap-4': isMobile,
          'justify-between w-full': !isMobile,
        })}
      >
        <div className='flex items-center'>
          <Typography
            type={isMobile ? 'display-50' : 'display-100'}
            className={classNames(
              `shrink ${
                status === ChecklistItemStatus.notStarted
                  ? 'underline decoration-dashed underline-offset-4 decoration-1'
                  : 'line-through'
              }`,
              {
                'max-w-[258px] text-lg': isMobile,
              }
            )}
          >
            {title}
          </Typography>
          {showGem && <GemButton />}
          {!isMobile && onWatchDemoClick && (
            <WatchDemoButton onClick={onWatchDemoClick} />
          )}
        </div>

        <div className='flex items-center gap-4'>
          {onSkipTaskClick && status === ChecklistItemStatus.notStarted && (
            <SkipTaskButton onClick={onSkipTaskClick} />
          )}
          {onMarkCompleteClick && status === ChecklistItemStatus.notStarted && (
            <MarkCompleteButton onClick={onMarkCompleteClick} />
          )}

          {/* At the moment this is only being used of Get ordained instantly */}
          {onAfterCompletedClick &&
            status === ChecklistItemStatus.completed &&
            ordainedButtonText && (
              <>
                {paidForOrdination && (
                  <Button
                    eventClick={(event) => {
                      event.stopPropagation();
                      // downloadCertificate();
                      void onClick();
                    }}
                    variant='text'
                  >
                    Access Credentials
                  </Button>
                )}
                {!paidForOrdination && (
                  <Button
                    eventClick={(event) => {
                      event.stopPropagation();
                      void onClick();
                    }}
                    variant='text'
                  >
                    Claim Certificate
                  </Button>
                )}
              </>
            )}
          {isMobile && onWatchDemoClick && (
            <WatchDemoButton onClick={onWatchDemoClick} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChecklistItem;

/* eslint-disable -- TODO: fix eslint errors */

import { useState } from 'react';

import { getOrders, Order } from '../api/paywall';

const useOrders = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [ordainedOrders, setOrdainedOrders] = useState<Order[]>([]);
  const [ordersFetched, setOrdersFetched] = useState<boolean>(false);

  const fetchOrders = async () => {
    try {
      const response = await getOrders();
      setOrdersFetched(true);
      if (response.success) {
        setOrders(response.orders);
        const ordainedOrders = response.orders.filter(
          (order) => order.is_ordination_order === true
        );
        setOrdainedOrders(ordainedOrders);
      }
    } catch (error) {}
  };

  return {
    orders,
    ordainedOrders,
    ordersFetched,
    fetchOrders,
  };
};

export default useOrders;
